
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTHSOCIALLOGIN = 'AUTHSOCIALLOGIN';
export const RESET_SUCCESS = 'RESET_SUCCESS';

export const SET_AUTH_REDIRECT_PATH= 'SET_AUTH_REDIRECT_PATH';

export const GETPERFIL_ROLSTART = 'GETPERFIL_ROLSTART';
export const GETPERFIL_ROLSUCCESS = 'GETPERFIL_ROLSUCCESS';
export const GETPERFIL_ROLFAIL = 'GETPERFIL_ROLFAIL';
export const CLEARPERFIL_ROLES = 'CLEARPERFIL_ROLES';

export const ADD_PRODUCT = 'ADD_PRODUCT' ;
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT' ;
export const REMOVE_PRODUCT_ESPECIFIC = 'REMOVE_PRODUCT_ESPECIFIC' ;
export const REMOVE_PRODUCTLIST = 'REMOVE_PRODUCTLIST' ;
export const SET_PRODUCT = 'SET_PRODUCT' ;
export const FETCH_PRODUCT_FAIL = 'FETCH_PRODUCT_FAIL' ;
export const CLEAN_CARTBUILDER = 'CLEAN_CARTBUILDER';

export const ACTIVATE_ORDER = 'ACTIVATE_ORDER' ;
export const VALID_FORMCONTACT = 'VALID_FORMCONTACT';