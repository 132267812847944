import * as actionTypes from '../Actions/actionTypes';
import {updateObject} from '../../Shared/utility';

const stateIinitialize = {
    token:null,
    userId: null,
    fullName:'',
    error: null,
    loading:false,
    authRedirectPath:'/',
    mensaje:null
}

const authStart = (state, action) =>{
    return updateObject(state, {error:false, loading: true})
}

const authSuccess = (state, action) =>{
    return updateObject(state, {
        token:action.idToken, 
        userId: action.userId,
        fullName: action.fullName,
        error: null,
        loading:false
    })
}

const authFail = (state, action) =>{
    return updateObject(state, {
        error: action.error,
        loading:false
    })
}

const resetSuccess = (state, action) =>{
    return updateObject(state, {
        mensaje: action.mensaje,
        loading:false
    })
}

const authLogout = (state, action) =>{
    return updateObject(state, {
        token:null,
        userId:null
    })
}

const setAuthRedirectPath = (state, action) => {
    return updateObject(state, {
        authRedirectPath:action.path
    })
}

const reducer = (state = stateIinitialize, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action)
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state, action);
        case actionTypes.RESET_SUCCESS: return resetSuccess(state, action);
        default: return state;
    };
}

export default reducer;